import React from "react";
import dynamic from "next/dynamic";
import Script from "next/script";
import LazyLoad from "react-lazyload";

import Layout from "../components/layout/layout";
import HomeSlider from "../components/home/home-slider/index";

import LogoBar from "../components/share/logo-bar/index";
import SimpleSection from "../components/home/simple-section/index";
import ExpectResult from "../components/home/expect-result/index";
import ServicesDetail from "../components/home/service-detail/index";
import ChooseProgram from "../components/share/choose-program/index";
import HomeSliderVideo from "components/home-slider-video";

const WaitingSection = dynamic(() => import("../components/home/waiting-section/index"));
const TestimonialSlider = dynamic(() => import("../components/home/testimonial-slider/index"));
const JumpMike = dynamic(() => import("../components/home/jump-mike/index"));
const MeetCoach = dynamic(() => import("../components/home/meet-coach/index"));
const LatestContent = dynamic(() => import("../components/share/latest-content/index"));

function Home(props) {
  const sliders = [
    {
      title: "get in the best",
      subtitle: "shape of your life",
      text: "Give us 3 weeks, and I guarantee we’ll change your life",
      name: " - Mike Dolce",
      sources: [
        <source key="1024jpg" srcSet={"/static/images/banner1.png"} type="image/jpg" media="(min-width: 1024px)" />,

        <source key="768jpg" srcSet={"/static/images/banner1.png"} type="image/jpg" media="(min-width: 768px)" />,

        <source key="412jpg" srcSet={"/static/images/banner-min-1.png"} type="image/jpg" media="(min-width: 412px)" />,

        <source key="360jpg" srcSet={"/static/images/banner-min-1.png"} type="image/jpg" media="(min-width: 360px)" />,
      ],
      url: "/static/images/banner1.png",
      textButton: "Let's do this!",
      video: '/static/videos/FAMOUS-HEADED-INTRO.mp4',
    },
    {
      title: "make the change",
      subtitle: "today",
      text: "lose 21 lbs. in 21 days!",
      name: "",
      sources: [
        <source key="1024jpg" srcSet={"/static/images/bg-slider2.jpg"} type="image/jpg" media="(min-width: 1024px)" />,

        <source key="768jpg" srcSet={"/static/images/m-bg-slide2.jpg"} type="image/jpg" media="(min-width: 768px)" />,

        <source key="412jpg" srcSet={"/static/images/m-bg-slide2.jpg"} type="image/jpg" media="(min-width: 412px)" />,

        <source key="360jpg" srcSet={"/static/images/m-bg-slide2.jpg"} type="image/jpg" media="(min-width: 360px)" />,
      ],
      url: "/static/images/bg-slider2.jpg",
      textButton: "Let's do this!",
    },
  ];

  return (
    <>
      <Script src={`https://thedolcediet.activehosted.com/f/embed.php?id=${process.env.NEXT_PUBLIC_ACTIVE_CAMPAIGN_FORM_ID}`} type="text/javascript" charset="utf-8"></Script>
      <Layout h1={"Make the change today"}>
        <div className="home-container">
          <HomeSlider sliders={sliders} />
          <LogoBar />
          <SimpleSection />
          <ExpectResult />
          <ServicesDetail />
          <ChooseProgram />
          <LazyLoad offset={500} once>
            <TestimonialSlider data={props.testimonialData} />
          </LazyLoad>

          <LazyLoad offset={600} once>
            <WaitingSection />
          </LazyLoad>

          <LazyLoad height={200} once>
            <MeetCoach />
          </LazyLoad>

          <LazyLoad height={200} once>
            <JumpMike />
          </LazyLoad>

          <LazyLoad height={200} once>
            <LatestContent data={props.articles} />
          </LazyLoad>
        </div>
      </Layout>
    </>
  );
}

export function getServerSideProps({ _, res }) {
  res.setHeader("Cache-Control", "public, s-maxage=60, stale-while-revalidate=59");

  return {
    props: {},
  };
}

export default Home;

import React, { useCallback, useEffect, useState, useMemo } from 'react'
import Button from 'react-bootstrap/Button'
import { Navigation, Pagination } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import PropTypes from 'prop-types'
import { smoothScrollTo } from 'helpers/scrollTo'
import styles from './home-slider.module.scss'
import HomeSliderVideo from 'components/home-slider-video'
import { Lato } from 'next/font/google'

const latoFont = Lato({
  display: 'swap',
  subsets: ['latin'],
  style: ['normal'],
  weight: ['400']
})

function HomeSlider (props) {
  const [slidesPerView, setSlidesPerView] = useState(1)
  const [spaceBetween, setSpaceBetween] = useState(0)

  const params = useMemo(() => ({
    observer: true,
    observeParents: true,
    parallax: true,
    spaceBetween: spaceBetween,
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
      lockClass: null
    },
    slidesPerView: slidesPerView,
    pagination: {
      clickable: true
    }
  }), [slidesPerView, spaceBetween])

  const onSlideChangeTransitionEnd = useCallback(() => {
    const css = styles['swiper-container__swiper']
    const allVideos = document.querySelectorAll(`.${css} .swiper-slide:not(.swiper-slide-active) video`)
    if (allVideos?.length) {
      allVideos.forEach(video => video.pause())
    }
    const currentVideo = document.querySelector(`.${css} .swiper-slide.swiper-slide-active video`)
    currentVideo?.play()
  }, [])

  // const onSlideChange = (swiper) => {
  //   if (swiper.activeIndex === swiper.slides.length - 1) {
  //     // Go to the next slide (slide 1)
  //     swiper.slideTo(0)
  //   }

  //   setActiveSlide(swiper.activeIndex)
  // }

  useEffect(() => {
    if (props.slidesPerView) {
      setSlidesPerView(props.slidesPerView)
    }
    if (props.spaceBetween) {
      setSpaceBetween(props.spaceBetween)
    }
  }, [props])

  const moveToProgram = useCallback(() => {
    const element = document.getElementById('choose-program')
    if (element) {
      smoothScrollTo('#choose-program')
    }
  }, [])

  return (
    <section className={styles['swipper-wrapper']}>
      <Swiper
        {...params}
        modules={[Navigation, Pagination]}
        onSlideChangeTransitionEnd={onSlideChangeTransitionEnd}
        // onSlideChange={onSlideChange}
        preventInteractionOnTransition={true}
        slidesPerView={1}
        className={styles['swiper-container__swiper']}
      >
        {
          props.sliders?.map((slide, index) => {
            const isPriority = index === 1
            const imageProperty = isPriority ? { fetchpriority: 'high' } : { loading: 'lazy' }
            return (
              <SwiperSlide key={slide.url} virtualIndex={index}>
                <div className={styles['swiper-container__img-wrapper']}>
                  <picture>
                    {slide.sources.map(source => source)}
                    <img
                      {...imageProperty}
                      className={styles['swiper-container__img']} // Apply the className to the img element
                      src={slide.url}
                      alt={slide.text}
                      sizes="100vw"
                    />
                  </picture>
                </div>
                <div
                  className={
                    styles['swiper-container__caption'] +
                  (index === 0 ? ` ${styles['custom-slide']}` : '')
                  }
                >
                  <h3 className={styles['swiper-container__title'] + ' title'}>
                    {slide.title}
                  </h3>
                  <h4
                    className={
                      styles['swiper-container__sub-title'] + ' sub-title'
                    }
                  >
                    {slide.subtitle}
                  </h4>
                  <p className={styles['swiper-container__text'] + ' text pc'}>
                    {slide.text}
                    <span className={styles['swiper-container__brand']}>
                      {slide.name}
                    </span>
                  </p>
                  <p className={styles['swiper-container__text'] + ' mobile'}>
                      {slide.text}
                      <span className="brand">{slide.name} </span>
                    </p>
                  <Button
                    variant="danger"
                    className={
                      styles['swiper-container__move-program'] + ' bg-red'
                    }
                    onClick={moveToProgram}
                  >
                    {slide.textButton}
                  </Button>
                </div>
                {slide.video && (
                  <div className={styles['swiper-container__video-wrapper']}>
                    <HomeSliderVideo src={slide.video} poster='/static/images/famous-poster.jpg' prePlayLimit prePlayLoopRange={[1.5, 4]} />
                  </div>
                )}
              </SwiperSlide>
            )
          })}
        <div className={`arrow-prev ${styles['swiper-container__arrow-prev']} swiper-button-prev`}></div>
        <div className={`arrow-next ${styles['swiper-container__arrow-next']} swiper-button-next`}></div>
      </Swiper>
    </section>
  )
}

HomeSlider.propTypes = {
  slidesPerView: PropTypes.number,
  spaceBetween: PropTypes.number,
  sliders: PropTypes.array
}

export default HomeSlider

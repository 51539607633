/* eslint-disable react/display-name */
import React, { forwardRef, useCallback, useImperativeHandle, useRef, useState } from 'react'
import styles from './styles.module.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlayCircle } from '@fortawesome/free-regular-svg-icons'
import Tooltip from '@material-ui/core/Tooltip'

const HomeSliderVideo = forwardRef(({ src = '', prePlayLimit = true, prePlayLoopRange = [0, 3], poster = '' , tooltip='Celebrites sing the praises of Mike Dolce. Hear what Joe Rogan, UFC President, Dana White, MMA Coach of the Year, Firas Zahabi, UFC legend, Chael Sonnen, and more have to say about what Mike Dolce and The Dolce Diet can do for you.'}, ref) => {
  const videoRef = useRef()
  const [muted, setMuted] = useState(true)
  const [realPlay, setRealPlay] = useState(false)
  const [isPlaying, setIsPlaying] = useState(false)
  const [loop, setLoop] = useState(true)

  useImperativeHandle(ref, () => ({
    video: videoRef
  }))

  const playVideo = useCallback(() => {
    setMuted(false)
    setRealPlay(true)
    setLoop(false)
    videoRef.current.currentTime = 0
    videoRef.current.play()
  }, [])

  const onLoadedMetadata = useCallback(() => {
    if (!videoRef || !prePlayLimit) return
    if (!realPlay) {
      videoRef.current.currentTime = prePlayLoopRange[0]
    }
  }, [realPlay, prePlayLimit, prePlayLoopRange])

  const onTimeUpdate = useCallback(() => {
    if (!videoRef || !prePlayLimit) return
    if (!realPlay && prePlayLimit) {
      if (videoRef.current.currentTime > prePlayLoopRange[1]) {
        videoRef.current.currentTime = prePlayLoopRange[0]
      }
    }
  }, [realPlay, prePlayLimit, prePlayLoopRange])

  const togglePlay = useCallback(() => {
    if (isPlaying) {
      videoRef.current.pause()
    } else {
      videoRef.current.play()
    }
  }, [isPlaying])

  return (
    <div className={`${styles.wrapper} embed-responsive embed-responsive-16by9`}>
      <Tooltip title={tooltip}>
        <video
          ref={videoRef}
          width="320"
          height="240"
          autoPlay
          muted={muted}
          controls={false}
          loop={loop}
          playsInline
          onLoadedMetadata={onLoadedMetadata}
          onTimeUpdate={onTimeUpdate}
          onClick={togglePlay}
          onPlaying={() => setIsPlaying(true)}
          onPause={() => setIsPlaying(false)}
          onEnded={() => setIsPlaying(false)}
          poster={poster}
        >
          <source src={src} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </Tooltip>
      {!realPlay && (
        <Tooltip title={tooltip}>
          <button aria-label='button play video' className={styles.btnWrapper} onClick={playVideo}>
            <FontAwesomeIcon icon={faPlayCircle} />
          </button>
        </Tooltip>
      )}
      {realPlay && !isPlaying && (
        <Tooltip title={tooltip}>
          <button aria-label='button play video' className={styles.btnWrapper} onClick={togglePlay}>
            <FontAwesomeIcon icon={faPlayCircle} />
          </button>
        </Tooltip>
      )}
    </div>
  )
})

export default HomeSliderVideo
